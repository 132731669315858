import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  IconButton,
  Link,
  ListItemText,
  MenuItem,
  TableCell,
  TableRow,
} from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import {
  SPEED_OPTIONS,
  USER_OPTIONS,
  convertMinutesToDaysAndMinutes,
  truncateText,
} from "../../../utils";
import { ConfirmDialog } from "../../custom-dialog";
import FormProvider, { RHFSelect } from "../../hook-form";
import { useBoolean } from "../../hooks/use-boolean";
import Iconify from "../../iconify";
import Label from "../../label";
export default function UserTableRow({
  row,
  index,
  onUpdate,
  onInbox,
  onInOut,
  showError,
  onLeftClick,
  onDelete,
  onTimerMessageClick,
}) {
  const confirm = useBoolean();
  const methods = useForm();

  return (
    <>
      <TableRow hover>
        <TableCell>
          <ListItemText
            primary={
              <Link
                color="inherit"
                variant="subtitle2"
                onClick={() => null}
                sx={{ cursor: "pointer" }}
              >
                <span title={row?.user?.name}>
                  {truncateText(row?.user?.name, 12)}
                </span>
              </Link>
            }
            secondary={
              <span title={row?.user?.email}>
                {truncateText(row?.user?.email, 12)}
              </span>
            }
          />
        </TableCell>

        <TableCell sx={{ textAlign: "center" }}>
          <ListItemText
            primary={format(new Date(row?.createdAt), "dd MMM yyyy")}
            secondary={format(new Date(row?.createdAt), "p")}
            primaryTypographyProps={{ typography: "body2", noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: "span",
              typography: "caption",
            }}
          />
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <Label
            variant="soft"
            color={
              (row?.user?.status === "online" && "info") ||
              (row?.user?.status === "running" && "warning") ||
              "default"
            }
          >
            {row?.user?.status}
          </Label>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>{row?.running}</TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <ListItemText
            primary={`Days: ${
              convertMinutesToDaysAndMinutes(row?.timer || 0)?.days
            }`}
            secondary={`Hours: ${
              convertMinutesToDaysAndMinutes(row?.timer || 0)?.hours
            }`}
            primaryTypographyProps={{ typography: "body2", noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: "span",
              typography: "caption",
            }}
          />
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <FormProvider methods={methods} onSubmit={() => null}>
            <RHFSelect
              name="option"
              onChange={(event) =>
                onUpdate(
                  row?._id,
                  { option: event?.target?.value },
                  "blockchain",
                  index
                )
              }
              value={row?.option}
            >
              {USER_OPTIONS?.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </RHFSelect>
          </FormProvider>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <FormProvider methods={methods} onSubmit={() => null}>
            <RHFSelect
              name="speed"
              onChange={(event) =>
                onUpdate(
                  row?._id,
                  { speed: event?.target?.value },
                  "blockchain",
                  index
                )
              }
              value={row?.speed}
            >
              {SPEED_OPTIONS?.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </RHFSelect>
          </FormProvider>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              onInOut(`${row?.user?.name}'s In`, row?._id, "in", index);
            }}
          >
            {row?.in}
          </span>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              onInOut(`${row?.user?.name}'s Out`, row?._id, "out", index);
            }}
          >
            {row?.out}
          </span>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <Box display="flex" alignItems="center">
            <IconButton
              edge="end"
              color="inherit"
              onClick={() =>
                onInbox(`${row?.user?.name}'s Inbox`, row?.user?._id)
              }
            >
              <Iconify icon="material-symbols:forward-to-inbox-outline-sharp" />
            </IconButton>
          </Box>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              onLeftClick(
                `${row?.user?.name}'s Left Time`,
                row?._id,
                "left",
                index
              );
            }}
          >
            <ListItemText
              primary={`Days: ${row?.left?.days}`}
              secondary={`Hours: ${row?.left?.hours}`}
              primaryTypographyProps={{ typography: "body2", noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: "span",
                typography: "caption",
              }}
            />
          </span>
        </TableCell>
        <TableCell>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => {
              if (row?.left?.days === 0 && row?.left?.hours === 0) {
                showError(
                  "User cannot be unlocked with 0 Days and 0 Hours. Update Left First!",
                  {
                    variant: "error",
                  }
                );
                return;
              }
              onUpdate(
                row?.user?._id,
                { locked: !row?.user?.locked },
                "user",
                index
              );
            }}
          >
            {row?.user?.locked ? (
              <Iconify icon="mdi:checkbox-outline" />
            ) : (
              <Iconify icon="system-uicons:checkbox-empty" />
            )}
          </IconButton>
        </TableCell>

        <TableCell sx={{ textAlign: "center" }}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              onTimerMessageClick(
                `${row?.user?.name}'s Timer Message`,
                row?._id,
                "timerMessage",
                index
              );
            }}
          >
            <ListItemText
              primary={`Every: ${row?.timerMessage?.value || "Not Set"} ${
                row?.timerMessage?.value ? row?.timerMessage?.unit : ""
              }, ${row?.timerMessage?.isActive ? "Enabled" : "Disabled"}`}
              secondary={`Message: ${truncateText(
                row?.timerMessage?.message || "Not Set",
                35
              )}`}
              primaryTypographyProps={{ typography: "body2", noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: "span",
                typography: "caption",
              }}
            />
          </span>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <Box display="flex">
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => confirm.onTrue()}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete Account"
        content={`Are you sure want to delete ${row?.user?.name} Account?`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDelete(row?.user?._id);
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
