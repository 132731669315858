import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import AuthContext from "../../context/AuthContext";
import FormProvider, { RHFTextField } from "../hook-form";
import { useSnackbar } from "../snackbar";

// ----------------------------------------------------------------------

export default function ContactChangeLink({ linkData }) {
  const { enqueueSnackbar } = useSnackbar();
  const { user, changeContactLink } = useContext(AuthContext);

  const ChangeLinkSchema = Yup.object().shape({
    contactLink: Yup.string()
      .required("Link is required")
      .url("Please enter a valid URL"),
  });

  const defaultValues = {
    contactLink: linkData || "",
  };

  const methods = useForm({
    resolver: yupResolver(ChangeLinkSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (linkData) {
      setValue("contactLink", linkData);
    }
  }, [linkData, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const result = await changeContactLink(
        { link: data?.contactLink },
        user()?.token
      );
      enqueueSnackbar(result, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack component={Card} spacing={3} sx={{ p: 3 }}>
        <RHFTextField name="contactLink" type={"text"} label="Contact Link" />

        <LoadingButton
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ ml: "auto" }}
        >
          Save
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
