import PropTypes from "prop-types";
import { useBoolean } from "../../components/hooks/use-boolean";
import Header from "./header";
import Main from "./main";
import NavHorizontal from "./nav-horizontal";

export default function DashboardLayout({ children }) {
  const nav = useBoolean();
  const renderHorizontal = <NavHorizontal />;

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      {renderHorizontal}

      <Main>{children}</Main>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
