// sections
import { ContactView } from "../../../components/account/view";

export const metadata = {
  title: "Dashboard: Contact Settings",
};

export default function ContactPage() {
  return <ContactView />;
}
