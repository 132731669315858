// components/PrivateRoute.js
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import DashboardLayout from "../../layouts/dashboard";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn } = useContext(AuthContext);

  return isLoggedIn ? (
    <DashboardLayout>
      <Component {...rest} />
    </DashboardLayout>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
