import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { formatDate, TIMER_MESSAGE_UNIT_OPTIONS } from "../../utils";
import FormProvider, { RHFSelect, RHFTextField } from "../hook-form";
import Iconify from "../iconify/iconify";

const CustomModal = ({
  open,
  onClose,
  value,
  placeholder,
  setValue,
  title,
  buttonName,
  isLoading,
  messages,
  isMessageLoading,
  onSubmit,
  userId,
  messageEndRef,
  type,
  methods,
  errorMsg,
  password,
  hoveredRow,
  setHoveredRow,
  deleteMessageApi,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle
        sx={{
          margin: "0 auto",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        {type === "timerMessage" ? (
          <Grid container mb={2} spacing={1}>
            <Grid item xs={12} sm={8}>
              <TextField
                id="value"
                name="value"
                label="Send Message After Every"
                size="medium"
                variant="outlined"
                type="number"
                fullWidth
                placeholder={"Type a number value.."}
                value={value?.value}
                onChange={(event) =>
                  setValue((previous) => ({
                    ...previous,
                    value: +event?.target?.value,
                  }))
                }
                style={{ marginTop: "1rem" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} marginTop={2}>
              <FormProvider methods={methods} onSubmit={() => null}>
                <RHFSelect
                  name="unit"
                  label="Unit"
                  onChange={(event) =>
                    setValue((previous) => ({
                      ...previous,
                      unit: event?.target?.value,
                    }))
                  }
                  value={value?.unit}
                >
                  {TIMER_MESSAGE_UNIT_OPTIONS?.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </FormProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="message"
                name="message"
                label="Message"
                placeholder="Type your message..."
                size="medium"
                variant="outlined"
                fullWidth
                value={value?.message}
                onChange={(event) =>
                  setValue((previous) => ({
                    ...previous,
                    message: event?.target?.value,
                  }))
                }
                style={{ marginTop: "1rem" }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={value?.isActive}
                    onChange={(event) =>
                      setValue((previous) => ({
                        ...previous,
                        isActive: event.target.checked,
                      }))
                    }
                  />
                }
                label="Active"
                labelPlacement="start"
              />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        <Stack spacing={2} pb={3}>
          {type === "message" && messages?.length && !isMessageLoading ? (
            <Box
              sx={{
                height: 200,
                overflowY: "auto",
              }}
            >
              <Table>
                <TableBody>
                  {messages?.map((message, index) => (
                    <TableRow
                      key={index}
                      hover
                      isHovered={hoveredRow === index}
                      onMouseEnter={() => setHoveredRow(index)}
                      onMouseLeave={() => setHoveredRow(null)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell
                        sx={{
                          padding: 0,
                          paddingBottom: 1,
                          paddingTop: 1,
                        }}
                      >
                        <span className="message-time">{`${formatDate(
                          message?.createdAt
                        )}: `}</span>
                        <span>{message?.content}</span>
                      </TableCell>
                      {hoveredRow === index && (
                        <IconButton
                          edge="end"
                          color="inherit"
                          onClick={() => deleteMessageApi(message?._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div ref={messageEndRef}></div>
            </Box>
          ) : null}
          {type === "message" && isMessageLoading && (
            <Box
              sx={{
                height: 200,
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {type === "message" && !isMessageLoading && !messages?.length && (
            <Box
              sx={{
                height: 200,
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>No Messages</div>
            </Box>
          )}
          {["message", "in", "out"]?.includes(type) && (
            <TextField
              size="medium"
              sx={{ width: "100%" }}
              variant="outlined"
              placeholder={placeholder}
              value={value}
              onChange={(event) => setValue(event?.target?.value)}
            />
          )}
          {type === "left" && (
            <TextField
              id="days"
              name="days"
              label="Days"
              size="medium"
              sx={{ width: "100%" }}
              variant="outlined"
              type="number"
              placeholder={placeholder?.first}
              value={value?.days}
              onChange={(event) =>
                setValue((previous) => ({
                  ...previous,
                  days: +event?.target?.value,
                }))
              }
              style={{ marginTop: "1rem" }}
              focused
            />
          )}
          {type === "left" && (
            <TextField
              id="hours"
              name="hours"
              label="Hours"
              size="medium"
              sx={{ width: "100%" }}
              variant="outlined"
              type="number"
              placeholder={placeholder?.second}
              value={value?.hours}
              onChange={(event) =>
                setValue((previous) => ({
                  ...previous,
                  hours: +event?.target?.value,
                }))
              }
              focused
            />
          )}
          {type === "user-create" && (
            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Stack spacing={2.5} style={{ marginTop: "1rem" }}>
                {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <RHFTextField name="firstName" label="First name" />
                  <RHFTextField name="lastName" label="Last name" />
                </Stack>

                <RHFTextField name="email" label="Email address" />

                <RHFTextField
                  name="password"
                  label="Password"
                  type={password.value ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={password.onToggle} edge="end">
                          <Iconify
                            icon={
                              password.value
                                ? "solar:eye-bold"
                                : "solar:eye-closed-bold"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </FormProvider>
          )}
          <LoadingButton
            variant="contained"
            loading={isLoading}
            color="primary"
            onClick={() => onSubmit(userId, value, type)}
            sx={{
              width: "fit-content",
              margin: "0 auto",
            }}
          >
            {buttonName}
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default CustomModal;
