export const USER_OPTIONS = ["A", "B"];
export const TIMER_MESSAGE_UNIT_OPTIONS = ["Minutes", "Hours", "Days"];

export const SPEED_OPTIONS = ["20/sec", "40/sec"];

export const getCheckedValue = (options, value, key, enabledKey) => {
  const val = options?.find((item) => item?.[key] === value?.[key]);
  if (val) {
    return val?.[enabledKey];
  }
};

export const formatDate = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const day = date?.getDate()?.toString()?.padStart(2, "0");
  const month = (date?.getMonth() + 1)?.toString()?.padStart(2, "0");
  const year = date?.getFullYear()?.toString()?.slice(2);
  const hour = date?.getHours() % 12 || 12;
  const minute = date?.getMinutes()?.toString()?.padStart(2, "0");
  const ampm = date?.getHours() < 12 ? "AM" : "PM";

  return `${day}-${month}-${year}, ${hour}:${minute} ${ampm}`;
};

export const convertMinutesToDaysAndMinutes = (minutes) => {
  if (typeof minutes !== "number" || minutes < 0) {
    return "Invalid input";
  }

  const days = Math.floor(minutes / (24 * 60));
  const remainingHours = minutes % (24 * 60);
  const hours = parseFloat((remainingHours / 60).toFixed(1));

  return { days, hours };
};

export const truncateText = (text, maxLength) => {
  return text?.length > maxLength
    ? text?.substring(0, maxLength - 1) + "..."
    : text;
};

export function localStorageAvailable() {
  try {
    const key = "__some_random_key_you_are_not_going_to_use__";
    window.localStorage.setItem(key, key);
    window.localStorage.removeItem(key);
    return true;
  } catch (error) {
    return false;
  }
}

export function localStorageGetItem(key, defaultValue = "") {
  const storageAvailable = localStorageAvailable();

  let value;

  if (storageAvailable) {
    value = localStorage.getItem(key) || defaultValue;
  }

  return value;
}
