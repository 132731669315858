import { Card, Stack } from "@mui/material";
// import { useResponsive } from 'src/hooks/use-responsive';
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import Login from "../components/Login/Login";
import Logo from "../components/logo";
import AuthContext from "../context/AuthContext";

// ----------------------------------------------------------------------

export default function LoginPage() {
  // const upMd = useResponsive('up', 'md');
  const { isLoggedIn } = useContext(AuthContext);

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: "auto",
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        zIndex: 1,
      }}
    >
      <Logo
        sx={{
          mt: { xs: 2, md: 8 },
          mb: { xs: 10, md: 8 },
        }}
      />

      <Card
        sx={{
          py: { xs: 5, md: 2 },
          px: { xs: 3, md: 2 },
          // boxShadow: { md: "none" },
          // overflow: { md: "unset" },
          bgcolor: { md: "background.default" },
        }}
      >
        <Login />
      </Card>
    </Stack>
  );

  return isLoggedIn ? (
    <Navigate to="/dashboard/home" />
  ) : (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: "100vh",
        position: "relative",
        "&:before": {
          width: 1,
          height: 1,
          zIndex: -1,
          content: "''",
          position: "absolute",
          backgroundSize: "cover",
          opacity: { xs: 0.24, md: 0 },
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundImage: "url(/assets/background/overlay_4.jpg)",
        },
      }}
    >
      {renderContent}
      {/* {upMd &&  */}
      {/* {renderSection} */}
    </Stack>
  );
}
