import Container from "@mui/material/Container";
import Tab from "@mui/material/Tab";
import { CircularProgress, Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import { useCallback, useEffect, useState } from "react";
import CustomBreadcrumbs from "../../custom-breadcrumbs";
import Iconify from "../../iconify";
import ContactChangeLink from "../contact-change-link";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

// ----------------------------------------------------------------------

const TABS = [
  // {
  //   value: 'general',
  //   label: 'General',
  //   icon: <Iconify icon="solar:user-id-bold" width={24} />,
  // },
  // {
  //   value: 'billing',
  //   label: 'Billing',
  //   icon: <Iconify icon="solar:bill-list-bold" width={24} />,
  // },
  // {
  //   value: 'notifications',
  //   label: 'Notifications',
  //   icon: <Iconify icon="solar:bell-bing-bold" width={24} />,
  // },
  // {
  //   value: 'social',
  //   label: 'Social links',
  //   icon: <Iconify icon="solar:share-bold" width={24} />,
  // },
  {
    value: "contact-link",
    label: "Contact Link",
    icon: (
      <Iconify
        icon="material-symbols:connect-without-contact-rounded"
        width={24}
      />
    ),
  },
];

// ----------------------------------------------------------------------

export default function ContactLinkView() {
  const [currentTab, setCurrentTab] = useState("contact-link");
  const [isLoading, setIsLoading] = useState(true);
  const [linkData, setLinkData] = useState("");

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const getContactLink = useCallback(async () => {
    try {
      setIsLoading(true);
      const endpoint = `${API_BASE_URL}/api/user/contact-link`;
      const response = await axios.get(endpoint);
      if (response?.status === 200) {
        setLinkData(response?.data || "");
      } else {
        throw new Error("Failed to fetch settings");
      }
    } catch (error) {
      setLinkData("");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const getContact = async () => {
      await getContactLink();
    };
    getContact();
  }, [getContactLink]);

  return (
    <Container maxWidth={"lg"} sx={{ zIndex: 1 }}>
      <CustomBreadcrumbs
        heading="Contact"
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {TABS.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>
      {isLoading && (
        <Box
          sx={{
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {currentTab === "contact-link" && !isLoading && (
        <ContactChangeLink linkData={linkData} />
      )}
    </Container>
  );
}
