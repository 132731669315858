import {
  Button,
  Card,
  Container,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AuthContext from "../../../context/AuthContext";
import CustomModal from "../../Modal";
import CustomBreadcrumbs from "../../custom-breadcrumbs";
import { ConfirmDialog } from "../../custom-dialog";
import { useBoolean } from "../../hooks/use-boolean";
// eslint-disable-next-line no-unused-vars
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ReconnectingWebSocket from "reconnecting-websocket";
import * as Yup from "yup";
import Iconify from "../../iconify/iconify";
import { useSnackbar } from "../../snackbar";
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from "../../table";
import UserTableRow from "./user-table-row";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const API_BASE_URL_WS = process.env.REACT_APP_API_URL_WS;

const TABLE_HEAD = [
  { id: "name", label: "Name" },
  { id: "createdat", label: "Created At" },
  { id: "status", label: "Status" },
  { id: "running", label: "Running" },
  { id: "timer", label: "Timer" },
  { id: "option", label: "Option" },
  { id: "speed", label: "Speed" },
  { id: "in", label: "In" },
  { id: "out", label: "Out" },
  { id: "inbox", label: "Inbox", width: 50 },
  { id: "left", label: "Left" },
  { id: "lock", label: "Lock", width: 50 },
  { id: "timerMessage", label: "Timer Message" },
  { id: "action", label: "Action", width: 50 },
];

export default function UserListView() {
  const messageEndRef = useRef();
  const { user, register } = useContext(AuthContext);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageLimit, setPageLimit] = useState(20);
  const table = useTable({ setPageNumber, setPageLimit });
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [isMessageSendLoading, setIsMessageSendLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useBoolean();
  const [modalValues, setModalValues] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);

  const [errorMsg, setErrorMsg] = useState("");
  const password = useBoolean();

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required("First name required"),
    lastName: Yup.string().required("Last name required"),
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const closeModal = useCallback(
    (result = null) => {
      if (result?._id && result?.user?._id) {
        setUsers((previosUsers) => [...previosUsers, result]);
      }
      setIsModalVisible(false);
      setModalValues("");
      setErrorMsg("");
      password?.onFalse();
      reset();
      setHoveredRow(null);
    },
    [setIsModalVisible, setModalValues, setErrorMsg, password, reset]
  );

  const userCreateApi = useCallback(
    async (data) => {
      try {
        const result = await register?.(
          data?.email,
          data?.password,
          `${data.firstName?.trim()} ${data?.lastName?.trim()}`,
          user()?.token
        );
        if (result) {
          console.log(result);
          enqueueSnackbar("User Created Successfully", {
            variant: "success",
          });
          closeModal(result?.blockChain);
        }
      } catch (error) {
        setErrorMsg(typeof error === "string" ? error : error?.message);
      }
    },
    [register, enqueueSnackbar, closeModal, setErrorMsg, user]
  );
  const formSubmit = handleSubmit(userCreateApi);

  useEffect(() => {
    let source = axios?.CancelToken?.source();
    let cancelPreviousRequest = false;
    setIsLoading(true);

    axios
      .get(`${API_BASE_URL}/api/blockchain/get-all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user()?.token}`,
        },
        cancelToken: source?.token,
      })
      .then((response) => {
        if (!cancelPreviousRequest) {
          setUsers((el) => response?.data || []);
          setUsersCount((el) => response?.data?.length || 0);
          setIsLoading((el) => false);
        }
      })
      .catch((error) => {
        if (!cancelPreviousRequest) {
          if (axios?.isCancel(error)) {
          } else {
            setUsers((el) => []);
            setUsersCount((el) => 0);
            enqueueSnackbar(
              error?.response?.status === 404
                ? "No Users Found"
                : "There was an error in fetching users!",
              { variant: "error" }
            );
          }
          setIsLoading(false);
        }
      });

    return () => {
      cancelPreviousRequest = true;
      source.cancel("Request cancelled by cleanup");
    };
  }, [pageNumber, pageLimit, enqueueSnackbar, user]);

  const updateApi = useCallback(
    async (id, newData, type, index) => {
      try {
        let endpoint;
        if (type === "user") {
          endpoint = `${API_BASE_URL}/api/user/update/${id}`;
        } else if (type === "blockchain") {
          endpoint = `${API_BASE_URL}/api/blockchain/update/${id}`;
        } else {
          return;
        }

        const response = await axios.patch(endpoint, newData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user()?.token}`,
          },
        });
        if (response?.status === 200) {
          setUsers((previosItem) =>
            previosItem?.map((item, i) =>
              index === i
                ? {
                    ...item,
                    ...(type === "user"
                      ? { user: { ...item?.user, ...newData } }
                      : newData),
                  }
                : item
            )
          );
          enqueueSnackbar("Successfully Updated", {
            variant: "success",
          });
        } else {
          throw new Error("Failed to Update");
        }
      } catch (error) {
        enqueueSnackbar("Failed to update. Please try again later.", {
          variant: "error",
        });
      }
    },
    [user, enqueueSnackbar]
  );

  const deleteUserApi = useCallback(
    async (id) => {
      try {
        const response = await axios.delete(
          `${API_BASE_URL}/api/user/delete/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user()?.token}`,
            },
          }
        );
        if (response?.status === 200) {
          setUsers((previosItem) =>
            previosItem?.filter((item) => item?.user?._id !== id)
          );
          enqueueSnackbar("User Deleted Successfully", {
            variant: "success",
          });
        } else {
          throw new Error("Failed to Update");
        }
      } catch (error) {
        enqueueSnackbar("Failed to delete. Please try again later.", {
          variant: "error",
        });
      }
    },
    [user, enqueueSnackbar]
  );

  const deleteMessageApi = useCallback(
    async (id) => {
      try {
        const response = await axios.delete(
          `${API_BASE_URL}/api/message/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user()?.token}`,
            },
          }
        );
        if (response?.status === 200) {
          setMessages((previosItem) =>
            previosItem?.filter((item) => item?._id !== id)
          );
          enqueueSnackbar("Message Deleted Successfully", {
            variant: "success",
          });
        } else {
          throw new Error("Failed to Update");
        }
      } catch (error) {
        enqueueSnackbar("Failed to delete. Please try again later.", {
          variant: "error",
        });
      }
    },
    [user, enqueueSnackbar]
  );

  const getMessagesApi = useCallback(
    async (id) => {
      try {
        setIsMessageLoading(true);
        const endpoint = `${API_BASE_URL}/api/message/user/${id}`;
        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${user()?.token}`,
          },
        });
        if (response?.status === 200) {
          setMessages((el) => response?.data || []);
        } else {
          throw new Error("Failed to fetch messages");
        }
      } catch (error) {
        enqueueSnackbar("Failed to fetch messages. Please try again later.", {
          variant: "error",
        });
      } finally {
        setIsMessageLoading(false);
      }
    },
    [user, enqueueSnackbar]
  );

  const sendMessageApi = useCallback(
    async (id, content, type) => {
      setIsMessageSendLoading(true);
      try {
        if (!content.trim()) return; // If content is empty or contains only whitespace, return
        const endpoint = `${API_BASE_URL}/api/message/send/${id}`;
        const response = await axios.post(
          endpoint,
          { content: content?.trim() },
          {
            headers: {
              Authorization: `Bearer ${user()?.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response?.status === 201) {
          setModalValues("");
          setMessages((previousMessages) => [
            ...previousMessages,
            response?.data,
          ]);
        } else {
          throw new Error("Failed to send message");
        }
      } catch (error) {
        enqueueSnackbar("Failed to send message. Please try again later.", {
          variant: "error",
        });
      } finally {
        setIsMessageSendLoading(false);
      }
    },
    [user, enqueueSnackbar]
  );

  const handleInbox = useCallback(
    (title, id) => {
      getMessagesApi(id);
      setIsModalVisible({
        open: true,
        title,
        userId: id,
        placeHolder: "Type a message to send",
        buttonName: "Send",
        type: "message",
        onSubmit: sendMessageApi,
      });
    },
    [setIsModalVisible, getMessagesApi, sendMessageApi]
  );

  const handleInOut = useCallback(
    (title, id, type, index) => {
      setModalValues(users?.[index]?.[type]);
      setIsModalVisible({
        open: true,
        title,
        userId: id,
        placeHolder: "Type (Max 7 Characters)",
        buttonName: "Save",
        type: type,
        onSubmit: (id, value, type) => {
          if (!value) {
            closeModal();
            return;
          }
          if (value?.length > 7) {
            enqueueSnackbar("Maximum of 7 Characters Allowed", {
              variant: "error",
            });
            return;
          }
          updateApi(id, { [type]: value }, "blockchain", index);
          closeModal();
        },
      });
    },
    [
      setIsModalVisible,
      setModalValues,
      users,
      closeModal,
      updateApi,
      enqueueSnackbar,
    ]
  );
  const handleCreateNewUser = useCallback(
    (title) => {
      setModalValues(null);
      setIsModalVisible({
        open: true,
        title,
        placeHolder: "",
        buttonName: "Create",
        type: "user-create",
        onSubmit: (id, value, type) => {
          formSubmit();
        },
      });
    },
    [setIsModalVisible, setModalValues, formSubmit]
  );

  const handleLeft = useCallback(
    (title, id, type, index) => {
      setModalValues(users?.[index]?.[type]);
      setIsModalVisible({
        open: true,
        title,
        userId: id,
        placeHolder: {
          first: "Enter Number Between 5 and 365",
          second: "Enter Number Between 0 and 23",
        },
        buttonName: "Save",
        type: type,
        onSubmit: (id, data, type) => {
          if (data?.days < 5 || data?.days > 365) {
            enqueueSnackbar("Enter Days Between 5 and 365", {
              variant: "error",
            });
            return;
          }
          updateApi(id, { left: { ...data } }, "blockchain", index);
          closeModal();
        },
      });
    },
    [
      setIsModalVisible,
      setModalValues,
      users,
      closeModal,
      updateApi,
      enqueueSnackbar,
    ]
  );

  const handleTimerMessageModal = useCallback(
    (title, id, type, index) => {
      setModalValues(users?.[index]?.[type]);
      setIsModalVisible({
        open: true,
        title,
        userId: id,
        buttonName: "Save",
        type: type,
        onSubmit: (id, data, type) => {
          if (data?.isActive && !data?.value) {
            enqueueSnackbar("Timer can not be enabled with 0 Value", {
              variant: "error",
            });
            return;
          } else if (data?.isActive && !data?.message?.trim()) {
            enqueueSnackbar("Timer can not be enabled with Empty Message", {
              variant: "error",
            });
            return;
          }
          updateApi(id, { timerMessage: { ...data } }, "blockchain", index);
          closeModal();
        },
      });
    },
    [
      setIsModalVisible,
      setModalValues,
      users,
      closeModal,
      updateApi,
      enqueueSnackbar,
    ]
  );

  // useEffect(() => {
  //   const ws = new WebSocket(`${API_BASE_URL_WS}?token=${user()?.token}`);

  //   ws.onopen = () => {
  //     console.log("WebSocket connected");
  //   };

  //   ws.onmessage = (event) => {
  //     const message = JSON.parse(event.data);
  //     if (message?.type === "update") {
  //       setUsers((previosItem) =>
  //         previosItem?.map((item) =>
  //           item?._id === message?._id
  //             ? {
  //                 ...item,
  //                 ...message?.updatedFields,
  //               }
  //             : item?.user?._id === message?._id
  //             ? {
  //                 ...item,
  //                 user: { ...item?.user, ...message?.updatedFields },
  //               }
  //             : item
  //         )
  //       );
  //     }
  //   };

  //   ws.onclose = () => {
  //     console.log("WebSocket disconnected");
  //   };
  // }, [user]);

  useEffect(() => {
    // Establish ReconnectingWebSocket connection when component mounts
    const url = `${API_BASE_URL_WS}?token=${user()?.token}`;
    const options = {
      connectionTimeout: 1000,
      maxRetries: Infinity,
      // debug: true,
    };
    const ws = new ReconnectingWebSocket(url, [], options);

    // Define event listeners
    ws.addEventListener("open", () => {
      console.log("WebSocket connected");
    });

    ws.addEventListener("message", (event) => {
      const message = JSON.parse(event.data);
      if (message?.type === "update") {
        setUsers((previousItem) =>
          previousItem?.map((item) =>
            item?._id === message?._id
              ? {
                  ...item,
                  ...message?.updatedFields,
                }
              : item?.user?._id === message?._id
              ? {
                  ...item,
                  user: { ...item?.user, ...message?.updatedFields },
                }
              : item
          )
        );
      }
    });

    ws.addEventListener("close", () => {
      console.log("WebSocket disconnected");
    });

    return () => {
      ws.close();
    };
  }, [user]);

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 0);
  }, [messages]);

  return (
    <>
      <Container maxWidth={"xl"} sx={{ zIndex: 1 }}>
        <CustomBreadcrumbs
          heading="Users"
          action={
            <Button
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
              onClick={() => handleCreateNewUser("Create New User")}
            >
              Create New User
            </Button>
          }
          sx={{ mb: { xs: 3, md: 5 } }}
        />
        <Card>
          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            {/* <Scrollbar> */}
            <Table size={"medium"} sx={{ minWidth: 960 }}>
              <TableHeadCustom headLabel={TABLE_HEAD} />
              {users?.length && !isLoading ? (
                <TableBody>
                  <>
                    {users?.length > 0 &&
                      users?.map((row, index) => (
                        <UserTableRow
                          key={row?._id}
                          index={index}
                          row={row}
                          onUpdate={updateApi}
                          onInbox={handleInbox}
                          onInOut={handleInOut}
                          showError={enqueueSnackbar}
                          onLeftClick={handleLeft}
                          onTimerMessageClick={handleTimerMessageModal}
                          onDelete={deleteUserApi}
                        />
                      ))}
                  </>
                </TableBody>
              ) : null}
              {isLoading ? (
                <TableBody>
                  {[...Array(table.rowsPerPage)].map((i, index) => (
                    <TableSkeleton key={index} sx={{ height: 80 }} />
                  ))}
                </TableBody>
              ) : null}
              <TableNoData notFound={!users?.length && !isLoading} />
            </Table>
            {/* </Scrollbar> */}
          </TableContainer>

          <TablePaginationCustom
            count={usersCount}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete{" "}
            <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              // handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
      <CustomModal
        open={isModalVisible?.open || false}
        onClose={closeModal}
        value={modalValues}
        placeholder={isModalVisible?.placeHolder}
        setValue={setModalValues}
        title={isModalVisible?.title}
        buttonName={isModalVisible?.buttonName}
        isLoading={isMessageSendLoading || isSubmitting}
        messages={messages}
        isMessageLoading={isMessageLoading}
        onSubmit={isModalVisible?.onSubmit}
        userId={isModalVisible?.userId}
        messageEndRef={messageEndRef}
        type={isModalVisible?.type}
        methods={methods}
        errorMsg={errorMsg}
        password={password}
        hoveredRow={hoveredRow}
        setHoveredRow={setHoveredRow}
        deleteMessageApi={deleteMessageApi}
      />
    </>
  );
}
