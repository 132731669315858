import React, { createContext, useState } from "react";
import axios from "axios";
const API_BASE_URL = process.env.REACT_APP_API_URL;

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token"));

  const login = async (email, password) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/user/admin-login`,
        {
          email,
          password,
        }
      );
      if (response?.status === 200) {
        const { token, username, email } = response?.data;
        const user = { username, email };
        localStorage.setItem("token", token);
        localStorage.setItem("userInfo", JSON.stringify(user));
        setIsLoggedIn(true);
        return true;
      } else {
        throw response?.data?.message;
      }
    } catch (error) {
      throw error?.response?.data?.message || "Authentication failed";
    }
  };

  const changePassword = async (data, token) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/user/change-password`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status === 200) {
        return "Password Updated Successfully";
      } else {
        throw new Error(response?.data?.message);
      }
    } catch (error) {
      throw new Error(
        error?.response?.data?.message || "Authentication failed"
      );
    }
  };

  const changeContactLink = async (data, token) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/user/contact-link`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status === 201) {
        return "Link Updated Successfully";
      } else {
        throw new Error(response?.data?.message);
      }
    } catch (error) {
      throw new Error(
        error?.response?.data?.message || "Unable to Update Link"
      );
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  };

  const user = () => ({
    ...JSON.parse(localStorage.getItem("userInfo")),
    token: localStorage.getItem("token"),
  });

  const register = async (email, password, name, token) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/user/signup-user-by-admin`,
        {
          email,
          password,
          name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status === 201) {
        return response?.data;
      } else {
        throw response?.data?.message;
      }
    } catch (error) {
      throw error?.response?.data?.message || "Failed to Register";
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        user,
        register,
        changePassword,
        changeContactLink,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
